import React from 'react'
import '../layout/layout.scss'
import '../common/pages.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function PaymentSuccessful() {
  const navigate = useNavigate()
  let auth = JSON.parse(localStorage.getItem('auth'))
  const handleHome = ()=>{
    navigate(`/pages/${auth?.access_code}`)
  }
  return (
    <div className='layout'>
      <div className='header'>
        <img onClick={handleHome} src="/images/Flick.svg" alt='logo'/>
      </div>

      <div className='main'>
        <div className='box'>
            <div className='success-box'>
                <img src='/images/successful.svg' alt='success'/>
                <p className='success'>Payment Successful</p>
                <p className='flick-test'>You made a payment of NGN 100.00 to Qraba’s page - flick test.</p>
            </div>
                <button className="btn" onClick={handleHome}>Go Home</button>
        </div>
        <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' />
            <div className='secured_content'>
                <p className='secured_p'>secured by</p>
                <img src='/images/flickLogo.svg' alt='logo'/>
            </div>
        </div>
      </div>
      <div className='footer'>
        {/* <span className='helpBtn'>Help</span> */}
        <div className='footer_menu'>
            <span>Terms</span>
            <span>Privacy</span>
            <span>Cookie settings</span>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default PaymentSuccessful
