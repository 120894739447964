import React from 'react'
import { encryptWithPublicKey } from './encrypt';
function Test() {
    const wwww = "4685882009752853|432|11/26|stanley Omeje";
    const encrypted = encryptWithPublicKey(wwww);
    
  return (
    <div>
      {console.log(encrypted)}
    </div>
  )
}

export default Test
