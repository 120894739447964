import React, { useState } from 'react'

import DashboardContext from './DashboardContext'

function DashboardProvider({children}) {
    const [dashData,setDashData] = useState(null)

  return (
    <DashboardContext.Provider value={{dashData,setDashData}}>
        {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider