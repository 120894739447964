// eslint-disable-next-line
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../components/layout/Layout';
import SelectDropDown from '../components/landing/SelectDropDown';
import '../components/landing/landing.scss'
import BankTransfer from '../components/bankTransfer/BankTransfer';
import Card from '../components/card/Card';
// import Bank from '../components/bank/Bank';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';

// eslint-disable-next-line
import {  useLocation, useNavigate } from 'react-router-dom';

const ErrorMessage = styled.div`
width:100%;
height:100%;
padding-top:1rem;
z-index: 3;
display: flex;
align-items: center;
justify-content: center;
margin-top:1rem;
background:#F7FCFC;
padding: 1rem;
.error_message{
  color: var(--grey-900, #101928);
  font-family: Circular Std;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
`
function Landing() {
  const location = useLocation();
  useEffect(()=>{
    getBankList();
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    const result = JSON.parse(localStorage.getItem('auth'))
    let {success} = location?.state || ""
   if(result){

     if(result?.source === "api" || result?.source === "dashboard"){
       return navigate('/pages/landing')
     }
   }
   if(success) return window.location.reload();
   return navigate('/pages/invalid')
    // eslint-disable-next-line
  },[])

  
  const auth = JSON.parse(localStorage.getItem('auth'))
  const dashData = JSON.parse(localStorage.getItem('dashResult'))
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
  // eslint-disable-next-line
  const [transferData,setTransferData] = useState({})
  // eslint-disable-next-line
  const [isLoading,setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [erro,setError] = useState(false);
  const [nubanList,setNubanList]  = useState([])
  const [transferId,setTransferId] = useState('')
  const [cardId,setCardId] = useState("")
  const [isNubanActive,setNubanActive] = useState(false)
  // eslint-disable-next-line
  
 


  // const {dashData} =  useContext(DashboardContext)
  
  const openModal = () => {
      setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  const handleBankSelection = (bankName) => {
    setSelectedBank(bankName);
    closeModal();
  };

  const getBankList = async () =>{
    try{
      const headers = {  
        Authorization:auth?.livePublicKey
      }

      const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-banks-list"
      let res = await axios.get(url,{headers:headers});
      setNubanList(res.data?.data);
      // console.log("nuban List",res.data)

    }catch(err){
      console.log(err)
    }
  }

  const createTransfer = async ()=>{
    
    let isDashboard = auth?.source === "dashboard";
    const result = {
      firstName:dashData?.firstName,
      lastName:dashData?.lastName,
      cust_email:dashData?.cust_email,
      Phoneno:dashData?.Phoneno,
      currency: dashData?.currency,
      amount:(auth?.isFixedAmount && auth?.amount !== "0") ? auth?.amount : dashData?.amount,
      channel: "dashboard",
      access_code:auth?.access_code,
      url:`https://checkout.paywithflick.co/pages/${auth?.access_code}`,
      currency_settled:dashData?.currency

    }
    try{
      
      const headers = {  
        Authorization:`Bearer ${auth?.livePublicKey}`
      }

     if(selectedBank.name === 'Transfer'){

      if(isDashboard){
        const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
        let res = await axios.post(url,{...result},{headers:headers});

        setTransferId(res.data?.data?.transactionId);
       
      }
    
     }else if(selectedBank.name === 'Card'){
        if(isDashboard){
          const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
          let res = await axios.post(url,{...result},{headers:headers});
          setCardId(res.data?.data?.transactionId)
         
        }
     }
      
        
    }catch(err){
      console.log(err)
      setError(true)
      const errorMessage = err?.message || "Server Error"
      toast.error(errorMessage);
      // window.location.reload();
      setIsLoading(false)
      setTimeout(() => {
        window.location.reload();
      }, 3000);
  
    }
    return
  }

  const renderContent = ()=>{
    if(selectedBank.name==="Card") return <Card cardId={cardId} setNubanActive={setNubanActive} />
    if(selectedBank.name==="Transfer") return <BankTransfer setNubanActive={setNubanActive} transferData={transferData}  nubanList={nubanList} transferId={transferId}/>
    // uncomment the below to show the pay by bank option  //
    // if(selectedBank.name==="Pay by Bank") return <Bank />
    
    // if(selectedBank.name === "Flick  one-click"){
    //   return setCommingSoon(true);
    // }
  }
 

  useEffect(()=>{
    
    createTransfer();
    // eslint-disable-next-line
  },[selectedBank])

  
  return (
    <Layout landing dataInput={dashData} selectedBank={selectedBank} >
        <div className='input_wrapper'>
                <h1 className='option_title'>Payment option</h1>
                <p className='option_desc'>Please select a preferred payment option</p>
                <div className="input-container" onClick={openModal}>
                    {selectedBank ? (
                        <>
                        <h3 className="bank-name">{selectedBank.name}</h3>
                        </>
                    ) : (
                        <span >Choose option</span>
                    )}
                    <img
                        src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
                        alt="arrow"
                        style={{marginLeft:"auto"}}
                    />
                </div>

                {(isModalOpen && !isNubanActive) && (
                <SelectDropDown
                    isNubanActive ={isNubanActive}
                    handleBankSelection={handleBankSelection}
                    closeModal={closeModal}
                    selectedBank={selectedBank}
                />
                )}
                
                {renderContent()}
                
                { selectedBank.name === "Flick  one-click" || selectedBank.name === "Pay by Bank" ? <ErrorMessage><h1 className='error_message'>Coming Soon!!!</h1></ErrorMessage>:""}
        </div>
    </Layout>
    
    
  )
}

export default Landing
