import React from 'react'
import './bank.scss'
function BankAcountDetails({name,account_number,amount, isActive, onClick }) {
  return (
    <div className={`bank_item ${isActive ? 'active' : ''}`} onClick={onClick}>
        <div className='bank_item_left'>
            <img src='/images/naira.svg' alt='naira'/>
            <div className='bank_item_content'>
                <p className='bank_item_name'>{name} <br/> <span className='bank_item_acc_num'></span>{account_number}</p>
                <h4 className='bank_item_amount'>{amount}</h4>
            </div>
        </div>
        {
            isActive &&
            <img src="/images/tick-circle.svg" alt='tick'/>
        }
    </div>
  )
}

export default BankAcountDetails
