import React, { useEffect, useRef, useState } from 'react'
import '../components/card/card.scss'
import BankTransfer from '../components/bankTransfer/BankTransfer';
import Bank from '../components/bank/Bank';
import Layout from '../components/layout/Layout';
import { useNavigate,useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import axios from 'axios';

function CardOtp() {
    useEffect(()=>{
      if(!localStorage.getItem('auth')) return navigate('/')
      // console.log("pin validation",location?.state?.cardValues)
      // if(!location?.state?.cardValues) return navigate('/')    
    // eslint-disable-next-line
    },[])
    const auth = JSON.parse(localStorage.getItem('auth'))
    
    const dashData = JSON.parse(localStorage.getItem('dashResult'))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState(""); 
    const [otp, setOtp] = useState(['', '', '', '','','']); 
    const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null),useRef(null),useRef(null)]; // Create refs for each input field
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [isNubanActive,setNubanActive] = useState(true)


    let phone;

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };


    if(auth?.source === "dashboard"){
      phone = dashData?.Phoneno

    }else if(auth?.source === "api"){
      phone = auth?.Phoneno || "";
    }

    const  {cardValues} =  location?.state || {}
    const accessCode = auth?.access_code || cardValues?.accessCode || ""
    const closeModal = () => {
      setIsModalOpen(false);
    };
    // eslint-disable-next-line
    const handleBankSelection = (bankName) => {
      setSelectedBank(bankName);
      closeModal();
    };
    
    const renderContent = ()=>{
      switch(selectedBank.name){
          case "Card":
              return "Card";
          
          case "Transfer":
              return <BankTransfer />;
         
          case "Pay by Bank":
              return <Bank />;
         
          default:
              return "Card"
      }
     
    }
    const handleInputChange = (e, index) => {
        let value = e.target.value;

        if (value.length > 1) {
            value = value.charAt(0);
        }
    
        if (/^[0-9]*$/.test(value) && index >= 0 && index < otpInputs.length) {
          otp[index] = value;
          setOtp([...otp]);
    
          // Auto focus on the next input field if available
          if (index < otpInputs.length - 1 && value !== '') {
            otpInputs[index + 1].current.focus();
          }
        }
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        const enteredOTP = otp.join('');
        setIsLoading(true);
        const pinData ={
           transactionId: cardValues?.transactionId || cardValues?.TransactionId ,
           otp: enteredOTP
        }

        try{
          const headers = {  
            Authorization:`Bearer ${auth?.livePublicKey}`
          }
            const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/verify-otp"
              
            let res = await axios.post(url,{...pinData},{
              headers:headers
            })
            const cardValues = {...res?.data,accessCode:accessCode}
            console.log(res.data)
            if(res.data.status==="success" && res.data.authorizationMode === "pin"){
                setIsLoading(false);
                navigate('/pages/card',{ state: { cardValues } })
            }else if(res.data.status==="success" && res.data.authorizationMode !=="pin" ){
                setIsLoading(false);
                localStorage.removeItem('card');
                let cardResponse = res.data
                console.log("card response Data: ",res.data)
                navigate('/pages/success',{ state: {cardResponse}})
            }else{
                setIsLoading(false);
                toast.error('unable to complete transaction')
                navigate(`/pages/landing`)
            }
            
          }catch(err){
            setIsLoading(false);
            console.log(err)
            const errorMessage = err?.response?.data?.message || "server error"
            toast.error(errorMessage);
          }
    }

    
  return (
    <Layout pathback="/pages/card">
        <div className='input_wrapper'>
            
            <h1 className='option_title'>Payment option</h1>
            <p className='option_desc'>Please select a preferred payment option</p>
            <div className="input-container" onClick={openModal}>
                    {selectedBank ? (
                        <>
                        <h3 className="bank-name">{selectedBank.name}</h3>
                        </>
                    ) : (
                        <span>Card</span>
                    )}
                    <img
                        src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
                        alt="arrow"
                        style={{marginLeft:"auto"}}
                    />


                </div>

                {/* {isModalOpen && (
                <SelectDropDown
                    isNubanActive ={isNubanActive}
                    handleBankSelection={handleBankSelection}
                    closeModal={closeModal}
                    selectedBank={selectedBank}
                />
                )} */}

                <div className='rule'></div>
                 {
                    selectedBank.name ==="Card" || !selectedBank.name   ?
                    <div className='card-details'>
                        <h1 className='card_h'>Enter OTP</h1>
                        <p className='card_p'>We just send a code to {`${String(phone)?.slice(0,7)}...${String(phone)?.slice(-2)}`} <span className='otp_resend'>Resend code</span></p>
                        <form onSubmit={handleSubmit} className='card_pin_form'>
                            <div className='card_pin_container' style={{display:"flex", gap:"5px"}}>
                            {otp.map((digit, index) => (
                                <input
                                key={index}
                                type='number'
                                maxLength="1"
                                className='pin'
                                value={digit}
                                onChange={(e) => handleInputChange(e, index)}
                                ref={otpInputs[index]}
                                autoFocus={index === 0}
                                />
                            ))}
                                
                            </div>
                            <button className='btn'>
                            {
                                isLoading ? (
                                  <BeatLoader
                                  color="#FFF"
                                  cssOverride={{}}
                                />
                                  
                                ) : (
                                  <>
                                    Confirming Details
                                  </>
                                )
                              }
                            </button>
                        </form>
                    </div>:
                    renderContent()
                 }
        </div>
    </Layout>
  )
}

export default CardOtp
