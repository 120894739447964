import React, { useEffect, useState } from 'react'
import '../components/landing/landing.scss'
import axios from 'axios';
import { FadeLoader } from 'react-spinners';
import {  useNavigate } from 'react-router-dom';

function VerifyPage() {
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(()=>{
        authUrl();
        // eslint-disable-next-line
    },[])
    const navigate = useNavigate();

    // const createId = async ()=>{
    //     const result = JSON.parse(localStorage.getItem('auth'))
    //     try{
          
    //         const headers = {  
    //           Authorization:"Bearer pk-U2FsdGVkX19EozcqQv2P2gvSotZKtVCsLn31P7k53fIMOr1nECZP3BBstNEmF0xu3vZeihtxDKqzLwM5nxCPor1sEGN1qjEWyP11Z0RYXpkvZ50XpFLE73R468tB6/Wk"
    //         }
            
    //         const transData ={
    //           channel: "api",
    //           amount: "100",
    //           Phoneno: "08067302418",
    //           access_code:result?.access_code
    //         }
           
    //         const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
    //         let res = await axios.post(url,{...transData},{
    //         headers:headers
    //         });

    //         console.log(res.data.data)
    //         const transactionId = res?.data?.data?.transactionId
    //         return transactionId; 
             
    //     }catch(err){
    //       console.log(err)
    //     }
    //     return
    // }

    const authUrl = async ()=>{

        setIsProcessing(true)
        const params  = window.location.href;
        const urlId = params.split('/');
        const id = urlId[urlId.length-1]
       
        try {
          const url = `https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/payment-page-authenticator/${id}`;
          const res = await axios.get(url)
          console.log("verify",res.data)
          const result = res?.data?.data 
          const strResult = JSON.stringify(result);
          localStorage.setItem("auth",strResult);
          localStorage.setItem('transfer', result?.transactionId);      

          if(res?.data?.verificationStatus==="verified"){
            if(res?.data?.data?.source === "dashboard" ){
               navigate('/pages/dashboard')
               setIsProcessing(false)
               return
            }else if(res?.data?.data?.source === "api"){
              if(res?.data?.IsCompletedPayment) return navigate('/invalid')
              navigate('/pages/landing')
              setIsProcessing(false)
              return
            }

            return '/pages/landing'
            // const transactionId = await createId();
          }

          else if(res?.data?.verificationStatus!=="verified" || res?.data?.IsCompletedPayment) {
            return navigate('/invalid')
          }
    
        } catch(error) {
         return navigate('/invalid')
          
        }
    }
  return (
    <div className="container_overlay">
          <div className='loading_overlay' style={{background:"grey"}}>
              <div className='loader_icon' >
                {
                    isProcessing &&
                    <FadeLoader 
                    cssOverride={{marginRight:"auto",marginLeft:"auto",marginTop:"50vh"}}
                    color="#259792"
                    />
                }
              </div>
          </div>
   </div>
  )
}

export default VerifyPage
