import React from 'react'
import './layout.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Layout(props) {
  const navigate = useNavigate()
  const handleBack = ()=>{
    navigate(props.pathback);
  }
  const auth = JSON.parse(localStorage.getItem('auth'));
  const dashData = JSON.parse(localStorage.getItem('dashResult'))

  // const handleHome = ()=>{
  //   navigate('/')
  // }
  
// make a query for the details of transactions  //
const businessName = auth?.business_name;
let businessName2 = businessName.split(' ')[0];
console.log(businessName2);

const email = businessName2;
// const amount = (auth?.source === "api" && auth?.currency_settled !== "NGN") ? `${auth?.cbInfo?.payableAmountString}`:(auth?.source==="api" && auth?.currency_settled === "NGN") && `${auth?.cbInfo?.payableAmountString}`;
const amount = (auth?.source === "api") && auth?.cbInfo?.payableAmountString
const currency_settled =  auth?.currency_settled;
const usdamount = (auth?.source==="api" && auth?.currency_settled !== "NGN") ? `${auth?.cbInfo?.payableFxAmountString}`:(auth?.source==="dashboard" && auth?.currency_settled !== "NGN") ? dashData?.cbInfo?.payableFxAmountString:"";
// const dashAmount =(auth?.source ==="dashboard" && auth?.currency_settled !== "NGN") ? `${dashData?.cbInfo?.payableAmountString}`: `${Number(dashData?.amount)/100}`;
const dashAmount =(auth?.source ==="dashboard") && dashData?.cbInfo?.payableAmountString
// eslint-disable-next-line

  return (
    <div className='layout'>
    
      <div className='header'>
        <img style={{width:"auto",height:"30px"}} src={auth?.avatar} alt=''/>
      </div>

      <div className='main'>
        <div className='box'>
           <div className='top_title'>
            {
            !props.landing &&
             <img onClick={handleBack} className='arrow_back' src='/images/backArrow.svg' alt='arrow' />
            }
            <h1 className={`title ${props.landing ? 'space':''}`}>Payment Information</h1>
          </div>
            <div className='balance'>
                <p className={`text_p1 ${auth?.source === "api"?props.selectedBank?.name==="Transfer" && "title_transfer":""}`}>{auth?.source === "dashboard" ? dashData.cust_email :auth?.source === "api" ? props.selectedBank?.name==="Transfer"? "Kindly Pay exact amount displayed; any other amount will be rejected by the bank" : email:email}</p>
                <div className='amount-content'>
                    <h1 className='balance_amount'>{ auth?.source === "dashboard" ? dashAmount : amount}</h1>
                    {

                        // ((this.props.currentUser.role == 'admin') || ( this.props.currentUser.role == 'contra'))
                        ((currency_settled === "USD") || (currency_settled === "GBP") || (currency_settled === "CAD") || (currency_settled === "EUR")) &&
                        <span className='dolla_amount'>{`${usdamount}`}</span>
                        
                    }
                </div>
            </div>
            {props.children}
        </div>

        <div className='securedBtn'>
            <img src='/images/lockKey.svg' alt='lock' />
            <div className='secured_content'>
                <p className='secured_p'>secured by</p>
                <img src='/images/flickLogo.svg' alt='logo'/>
            </div>
        </div>
      </div>
      <div className='footer'>
        {/* <span className='helpBtn'>Help</span> */}
        <div className='footer_menu'>
            <span>Terms</span>
            <span>Privacy</span>
            <span>Cookie settings</span>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Layout
