import React, { useState } from 'react'
import '../components/layout/layout.scss'
import '../components/bank/bank.scss'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
function BankID() {
    const navigate = useNavigate();
    const [formData,setFormData] = useState({
        userId_account:"",
        password:""
      });
     
 
    const  handleChange = (event)=>{
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }


      const handleSubmite = ()=>{
        console.log(formData);
        navigate('/account-select')
      }
      const handleBack = ()=>{
        navigate('/');
      }

      
  return (
    <div className='layout'>
      <div className='header'>
        <img onClick={handleBack} src="/images/Flick.svg" alt='logo'/>
      </div>

      <div className='main'>
        <div className='box'>
          <div className='top_title'>
            <img onClick={handleBack} className='arrow_back' src='/images/backArrow.svg' alt='arrow' />
          </div>
            
            
            <div className='bank_container'>
                <img className='bank_logo' src='/images/bankLogo.svg' alt='bank_logo' />
                <h1 className="bank_payment">Payment Information</h1>

                <form onSubmit={handleSubmite} className='bank_form'>
                    <input className='bank_account_id' type='text' name='userId_account' placeholder='Enter UserID or Account number' onChange={handleChange}/>
                    <div className='input_container'>
                        <div className='input_group'>
                            <input type='text' name="password" className='input_cardNumber' onChange={handleChange} />
                            <img src='/images/eye.svg'  alt='card' />
                        </div>
                        <p className='card_label'>password</p>
                    </div>
                    <button className='btn'>
                    Log In
                    </button>
                </form>
            </div>
        </div>
        <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' />
            <div className='secured_content'>
                <p className='secured_p'>secured by</p>
                <img src='/images/flickLogo.svg' alt='logo'/>
            </div>
        </div>
      </div>
      
      <ToastContainer />
    </div>
  )
}

export default BankID
