// eslint-disable-next-line
import React, { useContext, useState } from 'react'
import './card.scss'
import { useNavigate } from 'react-router-dom';
import { encryptWithPublicKey } from '../../utilities/encrypt';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';


function Card({cardId,setNubanActive}) {
  // const auth = JSON.parse(localStorage.getItem('auth'));

  const dashData = JSON.parse(localStorage.getItem('dashResult'))
  const auth = JSON.parse(localStorage.getItem('auth'))
  
   const navigate = useNavigate();
   
    const [formData,setFormData] = useState({
        name:"",
        card_number:"",
        card_date:"",
        card_cvv:""
    });
    const [expiry, setExpiry] = useState('');
    const [page,setPage] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    let transactionId;

    const changeActive = (selected)=>{
      setPage(selected);
    } 

    const amount = (auth?.source === "api") && auth?.cbInfo?.payableAmountString
    const dashAmount =(auth?.source ==="dashboard") && dashData?.cbInfo?.payableAmountString

    if(auth){
      if(auth.source === "dashboard"){
        transactionId = cardId
      }else if(auth.source === "api"){
         transactionId = auth.transactionId;
      }
    }

    const  handleChange = (event)=>{
      event.preventDefault();
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));

    }

    const handleInputChange = (e) => {
      let value = e.target.value;
      // Remove any non-digit characters
      value = value.replace(/\D/g, '');
      // Format the value with a slash
      if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }
      setExpiry(value);
    };
    const handleSubmite = async (e)=>{
      e.preventDefault();
      // eslint-disable-next-line
      setIsLoading(true);
      // eslint-disable-next-line
      // console.log("card payload: ",{...formData,transactionId,expiry})
      const {name,card_number,card_cvv} = formData;
      // eslint-disable-next-line
      const inputValues ={name,card_number,expiry,card_cvv}
      const data = `${card_number}|${card_cvv}|${expiry}|${name}`
      const encrypted = encryptWithPublicKey(data,auth?.livePublicEncKey);
      const cardData = {
        cardDetails: encrypted,
        transactionId: transactionId
      }
      console.log(cardData)
      try{
          const headers = {  
          Authorization:`Bearer ${auth?.livePublicKey}`
        }
        
        
        const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/charge"
        
        let res = await axios.post(url,{...cardData},{
          headers:headers
        }) ;
        
        console.log("response data: ",res.data)
        const cardValues = {...res.data,accessCode:auth.access_code}
        const {authorizationMode} = res.data
        setIsLoading(false);

        if(authorizationMode==="pin"){
           return navigate('/pages/card',{ state: { cardValues } })
          // console.log("condition pin: ",res.data)
        }else if(authorizationMode === "otp"){
           return navigate('/pages/card-otp',{ state: { cardValues } })
        }else if((authorizationMode).toLowerCase() === "3ds"){
          
          if((res.data.authMessage).toUpperCase() === "3DS URL" && res.data.responseUrl){  
            setIsLoading(false);
            const url = res.data.responseUrl;
            window.location.href = url;
            
          }else if((res.data.authMessage).toLowerCase()==="3ds" && !res.data.responseUr){
            if(authorizationMode === "otp" || authorizationMode === "3ds"){
              navigate('/pages/card-otp',{ state: { cardValues } })
            }
            setIsLoading(false);
            toast.error("unable to complete payment");
          }else{
            setIsLoading(false);
            toast.error("unable to complete payement");
          } 
        }
        else{
          console.log("condition not met ")
        }
      }catch(err){
        setIsLoading(false);
        console.log(err)
        const errorMessage = err?.response?.data?.message || "Server error"
        toast.error(errorMessage)
      }
    }
    
   
    
  return (
    <div className='card_container'>
      
      <div className='rule'></div>
      <h3 className='card_h'>Enter Card Information</h3>
      <form onSubmit={handleSubmite}>
        <div className='input_se'  onClick={() => changeActive("name")}>

          <input 
          className="card_name" 
          type='text' 
          placeholder='Card Name' 
          name="name"  
          onChange={handleChange}
          />
        </div>

        <div className='input_container'>
            <div 
            onClick={() => changeActive("card_num")}
            className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
            >

            <input 
            placeholder='5061 2345 6789 1234' 
            type='number' 
            className='input_cardNumber' 
            name="card_number" 
            onChange={handleChange} 
            />

            </div>
            <p className='card_label'>Card Number</p>
        </div>

        <div className='card_data_container'>
            <div 
            onClick={() => changeActive("card_date")}
            className={`card_date ${page==="card_date" ? 'clicked' : ''}`} 
            >
                <input
                 type='text' 
                 className='card_date_input' 
                 placeholder="MM/YY"  
                 id="expiry"
                 name="card_date" 
                  value={expiry}
                  maxLength="5"
                  
                 onChange={handleInputChange} 
                />
                <img src='/images/calendar.svg' alt='date' />
            </div>
            <div 
            onClick={() => changeActive("card_cvv")}
            className={`card_date ${page==="card_cvv" ? 'clicked' : ''}`}
            >
                <input 
                type='number' 
                placeholder='123' 
                maxLength="3" 
                className='card_date_input' 
                name='card_cvv' 
                onChange={handleChange} 
                />
                <img src='/images/lock.svg' alt='date' />
            </div>
        </div>
        <button  className='btn'>
          {
            isLoading ? (
              <BeatLoader
              color="#FFF"
              cssOverride={{}}
            />
              
            ) : (
              <>
               {`Pay ${auth?.source === "api" ? amount : auth?.source === "dashboard" && dashAmount}`}
              </>
              
            )
          }
        </button>
      </form>
    </div>
  )
}

export default Card
