import React, { useEffect } from 'react'
import Layout from '../components/layout/Layout'
import ConfirmBankTransfer from '../components/bankTransfer/ConfirmBankTransfer'
import { useNavigate,useLocation } from 'react-router-dom';


function Transfer() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    const auth = JSON.parse(localStorage.getItem("auth"))
    if(!auth && !location?.state?.transferData) return navigate('/')  
  // eslint-disable-next-line
  },[])
  const  {transferData} =  location?.state || {}
  return (
    <Layout pathback="/pages/landing">
      <ConfirmBankTransfer transferData={transferData} />
    </Layout>
  )
}

export default Transfer
