import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
function Redirect() {
    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false);
    const { param } = useParams();
    useEffect(()=>{
        authUrl();
        // eslint-disable-next-line
    },[])
    const authUrl = async ()=>{

        // setIsProcessing(true)
        // const params  = window.location.href;
        // const urlId = params.split('/');
        // // eslint-disable-next-line
        // const id = urlId[urlId.length-1];
        
        console.log("params: ",param)

        
        // try {
        //   const url = `https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/payment-page-authenticator/${id}`;
        //   const res = await axios.get(url)
        //   console.log("verify",res.data)
        //   const result = res?.data?.data 
        //   const strResult = JSON.stringify(result);
        //   localStorage.setItem("auth",strResult);
        //   localStorage.setItem('transfer', result?.transactionId);      

        //   if(res?.data?.verificationStatus==="verified"){

        //     if(res?.data?.data?.source === "dashboard" ){
        //        navigate('/pages/dashboard')
        //        setIsProcessing(false)
        //        return
        //     }else if(res?.data?.data?.source === "api"){
        //       if(res?.data?.IsCompletedPayment) return navigate('/invalid')
        //       navigate('/pages/landing')
        //       setIsProcessing(false)
        //       return
        //     }

        //     return '/pages/landing'
            
        //   }

        //   else if(res?.data?.verificationStatus!=="verified" || res?.data?.IsCompletedPayment) {
        //     return navigate('/invalid')
        //   }
    
        // } catch(error) {
        //  return navigate('/invalid')
          
        // }
    }
  return (
    <>
    <p></p>
    </>
  )
}

export default Redirect