import React, { useState } from 'react'
import Layout from '../components/layout/Layout'
import '../components/bank/bank.scss'
import BankAcountDetails from '../components/bank/BankAcountDetails'
import { toast } from 'react-toastify'

function BankAccount() {

  const dummyData = [
    {
        id:1,
        name:"AJIBADE AYOMIDE DAVID",
        account_number:"0224943454",
        amount:"NGN 2,000,000.89"

    },
    {
        id:2,
        name:"AJIBADE AYOMIDE DAVID",
        account_number:"0224943454",
        amount:"NGN 2,000,000.89"

    },
    {
        id:3,
        name:"AJIBADE AYOMIDE DAVID",
        account_number:"0224943454",
        amount:"NGN 2,000,000.89"

    }
  ]
// eslint-disable-next-line
  const [accountData,setAccountData] = useState(dummyData);
  const [activeBank, setActiveBank] = useState(null);

  const handleBankClick = (bankId) => {
    setActiveBank(bankId);
  };

  const handleConfirmPayment = () => {
    
    if (activeBank !== null) {
      const selectedBank = accountData.find((bank) => bank.id === activeBank);
      if (selectedBank) {
        
        const { id, name } = selectedBank;

        toast.success("payment successful");
        console.log(setAccountData)
        //  API call here using id 
        console.log('Selected Bank ID:', id);
        console.log('Selected Bank Name:', name);
      }
    }
  };
  
  return (
    <Layout pathback={"/bank"}>
        <div className='bank_select_account'>
            <h1 className='bank_select_title'>Accounts</h1>
            <div className='bank_accounts_option'>
            {accountData.map((bank) => (
            <BankAcountDetails
              key={bank.id}
              name={bank.name}
              account_number={bank.account_number}
              amount={bank.amount}
              isActive={bank.id === activeBank}
              onClick={() => handleBankClick(bank.id)}
            />
            ))}
                {/*  */}

                <button className='btn' onClick={handleConfirmPayment}>
                    Confirm Payment
                </button>
            </div>
        </div>
        
    </Layout>
  )
}

export default BankAccount
