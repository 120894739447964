import React, { useEffect, useRef, useState } from 'react';
 // eslint-disable-next-line
import BankTransfer from './BankTransfer';
import loadingTransfer from './loadingTransfer.json';
import loadingTransferEnd from './loadingTransferEnd.json';
import Lottie from 'lottie-react'
import { useNavigate } from "react-router-dom";
import './banktransfer.scss'


// eslint-disable-next-line 
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { copyToClipboard } from '../../utilities/clipboardUtils';


function ConfirmBankTransfer({transferData}) {
  const refcopy  = useRef(null);
  const navigate = useNavigate();
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  // eslint-disable-next-line 
  const [response, setResponse] = useState(null);
  // eslint-disable-next-line 
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeLimitExceeded, setIsTimeLimitExceeded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isSuccess,setIsSuccess] = useState(false)
  const toggleAccountDetails = () => {
    setShowAccountDetails(!showAccountDetails);
  };
  const auth = JSON.parse(localStorage.getItem('auth'))

  const transactionId = localStorage.getItem('nubanTrans')
  function timeout(number) {
    return new Promise( res => setTimeout(res, number) );
  }

  const handleGetHelp = ()=>{
    navigate('/pages/help')
  }


  useEffect(() => {
    let intervalId;
    let timeoutId;
    
    const makeRequest = async () => {

      try{

        const headers = {  
          Authorization:auth?.livePublicKey
        }
        
        // var metrans = localStorage.getItem('nubanTrans');      
        const url = `https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-verify?transactionId=${transactionId}`
        
        let res = await axios.get(url,{
          headers:headers
        });
        
        // const message = res?.data?.message.toLowerCase();
        const trans_status = res?.data?.data?.transaction_status?.toLowerCase();
        if(trans_status ==="success"){
          // console.log("final result: ",res?.data)
          const transferSuccess = res?.data?.data
          // toast.success(res.data.message);
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setIsSuccess(true)
          // setIsTimeLimitExceeded(true);
          localStorage.removeItem('transfer');
          await timeout(3000)
          navigate("/pages/success",{state:{transferSuccess}});
          // navigate away from this page // 
         //  navigate('/success')
        }else{
          console.log('Waiting for a successful response...');
        }
      }catch(err){
        console.log(err)
        setIsTimeLimitExceeded(true);
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        navigate("/pages/expire");
        const errorMessage = err?.response?.data?.message || err.message || "Server Error"
        toast.error(errorMessage);
      }
    };

    // Initialize the responseSuccessful flag to false
  
    const startPolling = () => {

      makeRequest();
      intervalId = setInterval(() => {
        makeRequest()
          .finally(() => {
            setIsLoading(false);
          });
      }, 5000); // 5 seconds

      // Set a timeout for 10 minutes (600,000 milliseconds)
      const timeoutDuration = 600000; // 10 minutes
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setIsTimeLimitExceeded(true);
        setIsLoading(false);
      }, timeoutDuration); // 10 minutes

      // Update timeLeft every second
      const updateTimer = () => {
        const elapsed = (timeoutDuration - (Date.now() - startTime)) / 1000;
        setTimeLeft(Math.max(0, Math.round(elapsed)));
      };
      
      const startTime = Date.now();
      const timerIntervalId = setInterval(updateTimer, 1000);

      // Clear the timer interval when the component unmounts
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        clearInterval(timerIntervalId);
      };
    };

    startPolling();
  
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (isTimeLimitExceeded) {
      navigate("/pages/expire");
    }
  }, [isTimeLimitExceeded, navigate]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  return (
      <div className="confirm-transfer">
        

        {
        !isSuccess ?
          <h1 className="confirm">We’re waiting to confirm your transfer. This can take a few minutes</h1>:
          <h1 className='confirm'>Transaction Successful</h1>
        }
        {
          !isTimeLimitExceeded ?
          <div className='lottie-size'>
            <Lottie animationData={loadingTransfer} /> 
          </div>:
          <div className='lottie-size'>
            <Lottie loop={false} animationData={loadingTransferEnd} /> 
          </div>

        }
        {
          isTimeLimitExceeded ?(

          <p onClick={handleGetHelp} style={{cursor:"pointer"}} className="sec-btn">
            Get help for this transaction
          </p>
          ):(
          <button className="sec-btn">
            Please wait for {formatTime(timeLeft)} minutes
          </button>
          )
        }

        <p className="show-account" onClick={toggleAccountDetails}>
          {showAccountDetails ? 'Hide account details' : 'Show account details'}
        </p>
        {showAccountDetails && 
        // <BankTransfer hideSendMoneyButton={true} transferData ={transferData} />
        <>
        <div className='bank_transfer'>
        <div className='bank_transfer_title_container'>
            <h3 className='bank_transfer_title'>Bank details</h3>
        </div>
        <div className='bank_transfer_content'>

          <div className='bank_row '>
              <span className='bank_light'>Account number</span>
              <span className='bank_light'>Bank</span>
          </div>
          
          <div style={{marginBottom:"20px"}} className='bank_row'>
            <div className='copy_div'>
                <input ref={refcopy} type='text' value={transferData.account_number} className='copyInput'/>
                <img onClick={copyToClipboard.bind(null,refcopy)} src="/images/copy.svg" alt='copy'/>
            </div>
            <span className='bank_thick'>{transferData.bank}</span>
          </div>

          <div className='bank_row '>
            <p className='bank_light'>Account type</p>
            <p className='bank_light'>Account name</p>
          </div>

          <div className='bank_row'>
            <span><img src='/images/Badge.svg' alt='badge' /></span>
            <span className='bank_thick'>{transferData.account_name}</span>
          </div>

        </div>

        </div>
          
        </>
        }
        <ToastContainer />
      </div>
  );
}

export default ConfirmBankTransfer;
