
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function ExpirePage() {
    let auth = JSON.parse(localStorage.getItem('auth'))
    const dashData = JSON.parse(localStorage.getItem('dashResult'))
    // let update ;
    const navigate = useNavigate();
    const location = useLocation();
    const  {cardResponse} =  location?.state || {}
    // eslint-disable-next-line
    const {transferSuccess} = location?.state || {}
    // eslint-disable-next-line
    let auths = {...auth}
    let amount="";
    let name="";
    let currency="";
    let url=auths?.redirectLink || auth?.redirectLink ||  '';
    let transactionId ='' ;
    let status = '';
   
    if(auths?.source === "api"){
      amount = auth?.amount;
      name =  auth?.business_name;
      currency =  auth?.currency;
      
      url =  auth?.redirectLink;
      transactionId = transferSuccess?.transaction_ref || cardResponse?.reference;
      status = transferSuccess?.transaction_status || cardResponse?.status;
    }else if(auth?.source  === "dashboard"){
      // eslint-disable-next-line
      amount = String(Number(dashData?.amount)/100);
      // eslint-disable-next-line
      name =  auth?.business_name;
      // eslint-disable-next-line
      currency = auth?.currency
      url=""
    }
    
  
  // function switchCurr(props) {
  //   const { userRole } = props;
  //   switch (userRole) {
  //     case 'NGN':
  //       return  "₦";
  //     case "GBP":
  //         return "£";
  //     default:
  //       return "₦";
  //     }
  // }
  
  // export default switchCurr;
  
  
  
  
      // eslint-disable-next-line
      // const accessCode = cardValues?.accessCode || ""
      const handleHome = ()=>{
          // window.location.reload();
          navigate(`/pages/landing`)
      }
      // eslint-disable-next-line
      const handleBack = ()=>{
          // Construct the URL with query parameters
          const externalUrl = `${url}?transactionId=${transactionId}&status=${status}`;
  
          // Redirect to the external URL
          localStorage.removeItem("success")
          if(auth?.source === "api"){
                localStorage.removeItem("auth")
          }
          window.location.href = externalUrl;
  
      }
  
    //   useEffect(() => {
    //     return () => {
    //         if (history.action === 'POP') {
    //             history.go(1);
    //         }
    //     };
    // }, [history]);
  
    useEffect(() => {
      const handleBeforeUnload = () => {
        // Clear local storage before unloading the page
        if(auth?.source === "api"){
          localStorage.removeItem("auth")
        }
      };
  
      const handlePopstate = () => {
        // Clear local storage when user navigates back
        if(auth?.source === "api"){
          localStorage.removeItem("auth")
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopstate);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handlePopstate);
      };
      // eslint-disable-next-line
    }, []);
     
    
    return (
      <div className='layout'>
        <div className='header'>
          <img onClick={handleHome} src="/images/Flick.svg" alt='logo'/>
        </div>
  
        <div className='main'>
          <div className='box'>
              <div className='success-box'>
                  <img src='/images/expires.svg' alt='success'/>
                  <p className='success'>Your payment is nearly finished! </p>
                  <p className='flick-test'>We will continue to monitor this transaction and notify you once it is completed.</p>
              </div>
  
              {
                url !=="null" && url !== "" ?
                <button onClick={handleBack} className='btn'>Close</button> :
              <button className="btn" onClick={handleHome}>Go Home</button>
              }
          </div>
          <div className='securedBtn'>
              <img src='/images/lockUnlocked.svg' alt='lock' />
              <div className='secured_content'>
                  <p className='secured_p'>secured by</p>
                  <img src='/images/flickLogo.svg' alt='logo'/>
              </div>
          </div>
        </div>
        <div className='footer'>
          {/* <span className='helpBtn'>Help</span> */}
          <div className='footer_menu'>
              <span>Terms</span>
              <span>Privacy</span>
              <span>Cookie settings</span>
          </div>
        </div>
       
      </div>
    )
}

export default ExpirePage



