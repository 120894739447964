import React from 'react'
import '../../components/landing/landing.scss'
import BankData from './BankData.json'
function BankDropDown({ handleBankSelection, selectedBank }) {
    const bankData = BankData;
    return (
        <div className="bank-list">
          <div className="modal-container">
            <div className="scrollable-list">
              {bankData.map((bank) => (
                <div
                  key={bank.id}
                  className={`icon-bank ${bank === selectedBank ? "selected active" : ""}`}
                  onClick={() => handleBankSelection(bank)}
                >
                  <div className="bank-name-icon">     
                      <h2 className="bank-name">{bank.name}</h2>
                    {bank === selectedBank && (
                      <img
                        src={process.env.PUBLIC_URL + "./images/bank-check.svg"}
                        alt="check"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* ... */}
        </div>
    )
}

export default BankDropDown
