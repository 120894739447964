import React from 'react';
import Layout from '../layout/Layout';


function GetHelp() {
 
  return (
    <Layout pathback="/">
      <div className="confirm-transfer">
        <h1 className="contact">If you have any issues with this transfer, please contact us via <span style={{color:"#2EBDB6"}}>support@getflick.app</span></h1>
        <button className="btn">Close checkout</button>
      </div>
    </Layout>
  );
}

export default GetHelp;
