import React, { useState } from 'react'
import BankDropDown from './BankDropDown';
import '../../components/landing/landing.scss';
import './bank.scss'
import { useNavigate } from 'react-router-dom';
function Bank() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
    const navigate = useNavigate();

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    const handleBankSelection = (bankName) => {
      setSelectedBank(bankName);
      closeModal();
    };
    const handleBankLogin = ()=>{
        navigate('/bank')
    }
  return (
    <div className='bank'>
      <div className='rule'></div>
      <div className='bank_select'>
        <h1 className='bank_title'>Select Bank</h1>
            <div className="input-container" onClick={openModal}>
                    {selectedBank ? (
                        <>
                        <h3 className="bank-name">{selectedBank.name}</h3>
                        </>
                    ) : (
                        <span >Select a Bank</span>
                    )}
                    <img
                        src={process.env.PUBLIC_URL + "./images/arrow-down.svg"}
                        alt="arrow"
                        style={{marginLeft:"auto"}}
                    />
            </div>
            {isModalOpen && (
            <BankDropDown 
                handleBankSelection={handleBankSelection}
                closeModal={closeModal}
                selectedBank={selectedBank}
            />
            )}
      </div>
      <button onClick={handleBankLogin} className='btn'>
      Log In
      </button>
    </div>
  )
}

export default Bank
