import React from 'react'
import styled from 'styled-components'

const ErrorPage = styled.div`
width:100%;
height:100vh;
background: #F6F7F9;
display:flex;
align-items: center;
justify-content:space-between;
flex-direction:column;
padding-bottom:6rem;
margin:0 auto;

`
const ErrorContent = styled.div`
border-radius: 10px;
background: #FFF;
width:100%;
max-width:380px;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
flex-direction:column;
padding-bottom:4rem;
margin-top:5vh;


.title{
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-top:3rem;

}
.text1{
    color: #fff;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.523%; /* 61.009px */
    background:yellow;
    width:fit-content;
    padding:1rem;
    margin-top:3rem;
    
}

`
function PageInvalid() {
  return (
    <ErrorPage >

    <ErrorContent>
            {/* <img src='/images/redWarning.jpg' alt='warning' />
            <h3 className='title'>Unable to process this transaction</h3> */}
            <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/orange-error-icon-0.png' alt='warning' width="45" />
                <h3 className='title error_invalid_header'>Unable to start this transaction</h3><br />
                <p className="error_invalid">It's either because the link is incorrect or the transaction is already completed</p>
    </ErrorContent>
        <div className='securedBtn'>
              <img src='/images/lockUnlocked.svg' alt='lock' width="20" />
              <div className='secured_content'>
               <p className='secured_p'>Secured by  </p>
                   <img src='/images/flickLogo.svg' alt='logo' width="45" />  
              </div>
        </div>
    </ErrorPage>
  )
}

// function PageInvalid1() {
//     return (
//       <div className='error_container'>
  
//           <div className='error_content' style={{marginTop:"5vh", paddingTop:"30px"}}>
//           <img src='https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/orange-error-icon-0.png' alt='warning' width="45" />
//                 <h3 className='title error_invalid_header'>Unable to start this transaction</h3><br />
//                 <p className="error_invalid">It's either because the link is incorrect or the transaction is already completed</p>
//           </div>
//           <div className='securedBtn'>
//               <img src='/images/lockUnlocked.svg' alt='lock' width="20" />
//               <div className='secured_content'>
//               <p className='secured_p'>Secured by  </p>
//                   <img src='/images/flickLogo.svg' alt='logo' width="45" />
//               </div>
//           </div>
//       </div>
//     )
//   }
  
export default PageInvalid
